import '../styles/globals.css';
import '@fontsource/roboto';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { ChakraProvider } from '@chakra-ui/react';
import TagManager from 'react-gtm-module';
import { ReactElement, useEffect, useMemo } from 'react';

import { StoreProvider, createStore } from '../store';
import { DesktopHeader, MobileHeader } from 'components/Header';
import Footer from 'components/Footer';
import ldTheme from '../theme';
import { NEXT_PREVIEW_COOKIE } from 'utils/constants';
import { PreviewBanner } from 'components/CMS';
import { useRouter } from 'next/router';
import { GTMPageViewEvent, GTMPageViewEventData } from 'utils/gtmHelpers';
import Inactivity from 'components/Inactivity';
import { fetchGlobalAppData, GlobalAppData } from 'api/contentful-fetch';
import { isInitialSiteLoad } from 'utils';

interface MyAppProps extends AppProps, GlobalAppData {
  isPreview?: boolean;
}

library.add(fas);
library.add(far);

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
function MyApp({
  Component,
  pageProps,
  isPreview,
  globalHeaderBanner,
  mainNavigation,
  footerNavigation
}: MyAppProps): ReactElement {
  const store = useMemo(() => {
    return createStore({ cmsStore: { globalHeaderBanner, mainNavigation, footerNavigation } });
  }, []);
  const { asPath } = useRouter();

  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID ?? '' });
  }, []);

  const pageTitle = asPath
    .split('/')
    ?.[asPath.split('/').length - 1]?.split('-')
    .map((s: string) => s.charAt(0).toUpperCase() + s.slice(1))
    .join('');

  const pageInfo: GTMPageViewEventData = {
    page_url: asPath,
    page_title: pageTitle !== '' ? pageTitle : 'Prescription Refills'
  };

  useEffect(() => {
    let mounted = true;

    if (!mounted) {
      return;
    }

    GTMPageViewEvent(pageInfo);

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  return (
    <StoreProvider value={store}>
      <ChakraProvider theme={ldTheme} resetCSS>
        <Head>
          <link rel="manifest" href="/manifest.webmanifest" />
          <link href="/icons/icon-16x16.png" rel="icon" type="image/png" sizes="16x16" />
          <link href="/icons/icon-32x32.png" rel="icon" type="image/png" sizes="32x32" />
          <link rel="shortcut icon" type="image/jpg" href="/favicon.ico" />
          <meta name="theme-color" content="#005dab" />
          <link rel="apple-touch-icon" href="/icons/icon-192x192.png"></link>
          {pageProps?.content?.pageTitleMeta && <title>{pageProps.content.pageTitleMeta}</title>}
          {pageProps?.content?.pageDescriptionMeta && (
            <meta name="description" content={pageProps.content.pageDescriptionMeta} />
          )}
          {process.env.ENVIRONMENT === 'dev' && <meta name="robots" content="noindex"/>}
          <script
            async
            src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}&libraries=places`}
          />
          <script src="https://cdn.noibu.com/collect.js"></script>
        </Head>
        <DesktopHeader />
        <MobileHeader />
        {isPreview && <PreviewBanner />}
        <Component {...pageProps} />
        <Footer />
        <Inactivity />
      </ChakraProvider>
    </StoreProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const app = await App.getInitialProps(appContext);
  const { ctx, Component } = appContext;
  try {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    // Check for the preview mode cookies
    const cookies = ctx?.req?.headers?.cookie;
    let isPreview = false;
    if (!!cookies) {
      const splitCookies = cookies.split('; ');
      const result: { [key: string]: string } = {};
      for (const i in splitCookies) {
        const cur = splitCookies[i].split('=');
        result[cur[0]] = cur[1];
      }
      if (result[NEXT_PREVIEW_COOKIE]) {
        isPreview = true;
      }
    }

    if (isInitialSiteLoad(ctx?.req?.url)) {
      const globalAppData = await fetchGlobalAppData();
      return {
        ...app,
        ...globalAppData,
        pageProps,
        isPreview
      };
    }

    return {
      ...app,
      pageProps,
      isPreview
    };
  } catch (error) {
    console.error('ERROR FETCHING MyApp PROPS');
    console.error(error);
    return { ...app };
  }
};

export default MyApp;
